.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease;
  }
  
  .modal-overlay.open {
    opacity: 1;
    pointer-events: auto;
  }
  
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    width: 90%;
    max-width: 600px;
    color: black;
    transform: translateY(-100%);
    transition: transform 0.3s ease;
  }
  
  .modal-content.slide-in {
    transform: translateY(0);
  }
  
  .modal-content.slide-out {
    transform: translateY(-100%);
  }
  
  .close-button {
    background-color: transparent;
    border: none;
    font-size: 18px;
    font-weight: bold;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
  
  .close-button:hover {
    color: red;
  }
  
  h2, h3, h4 {
    margin-top: 20px;
    color: #333;
  }
  
  p {
    margin-bottom: 10px;
  }

  
  .notify-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .notify-form label {
    display: flex;
    flex-direction: column;
    font-weight: bold;
    color: #333;
  }
  
  .notify-form input {
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 16px;
  }
  
  .notify-form button {
    padding: 10px 20px;
    background-color: #ff6600;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .notify-form button:hover {
    background-color: #e55b00;
  }
  