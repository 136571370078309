body, html {
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: black; /* Set the default text color to black */
}

/* Base styles for the Coming Soon page */
.coming-soon {
  background-image: url('./assets/final.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: 94vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  position: relative;
  padding: 20px;
  text-align: center;
}

/* Styles for large screens (desktops and laptops) */
@media (min-width: 1025px) {
  .countdown {
    font-size: 36px;
  }

  .content h2 {
    font-size: 28px;
  }

  .buttons button {
    font-size: 16px;
    padding: 10px 20px;
  }
}

/* Styles for tablets */
@media (max-width: 1024px) {
  .coming-soon {
    background-size: contain;
    background-position: top;
  }

  .countdown {
    font-size: 24px;
  }

  .content h2 {
    font-size: 22px;
  }

  .buttons button {
    font-size: 14px;
    padding: 8px 16px;
  }
}

/* Styles for mobile devices */
@media (max-width: 768px) {
  .coming-soon {
    background-size: cover;
    background-position: top;
    padding: 10px;
  }

  .countdown {
    font-size: 20px;
    flex-direction: column;
    gap: 5px;
  }

  .countdown-item {
    flex-direction: row;
  }

  .count {
    font-size: 20px;
  }

  .label {
    font-size: 10px;
  }

  .content h2 {
    color: black; /* Set the text color to black */
    font-size: 32px; /* Increase the font size */
    font-weight: bold;
    margin-bottom: 15px;
  }

  .content p {
    font-size: 14px;
    margin-bottom: 15px;
  }

  .content p {
    color: black; /* Change the paragraph text color to black */
    font-size: 18px; /* Increase the font size for the paragraph */
    margin-bottom: 20px;
  }

  .social-icons a {
    font-size: 18px;
    margin: 0 5px;
  }
}

/* Styles for very small devices (e.g., older small smartphones) */
@media (max-width: 480px) {
  .countdown {
    font-size: 16px;
    gap: 3px;
  }

  .count {
    font-size: 16px;
  }

  .label {
    font-size: 8px;
  }

  .content h2 {
    font-size: 18px;
  }

  .buttons button {
    font-size: 10px;
    padding: 5px 10px;
  }

  .social-icons a {
    font-size: 16px;
  }
}

.overlay {
  /* background-color: rgba(0, 0, 0, 0.6); */
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  width: 90%;
  max-width: 700px;
}

.logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.countdown {
  font-size: 24px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  gap: 10px;
}

.countdown-item {
  text-align: center;
}

.count {
  font-size: 36px;
  font-weight: bold;
  color: #ff6600;
}

.label {
  font-size: 12px;
  text-transform: uppercase;
  color: black;
}

.content h2 {
  color: black;
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 10px;
}

.content p {
  margin-bottom: 20px;
}

.buttons button {
  background-color: #ff6600;
  color: black;
  padding: 10px 20px;
  border: none;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
}

.buttons button:hover {
  opacity: 0.9;
}

.sparkminds-logo {
  max-width: 350px; /* Increase the size of the logo */
  margin-bottom: 15px; /* Adjust the spacing below the logo */
}

.logo p {
  font-size: 36px; /* Increase the size of the "Coming Soon" text */
  font-weight: 600;
  color: black; /* Change text color to black */
  margin-top: 10px; /* Adjust the margin above the text */
}

.social-icons {
  margin-top: 20px;
}

.social-icons a {
  color: black;
  font-size: 20px;
  margin: 0 10px;
  text-decoration: none;
  transition: color 0.3s;
}

.social-icons a:hover {
  color: #ff6600;
}

.notify-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.notify-form label {
  display: flex;
  flex-direction: column;
  font-weight: bold;
  color: #333;
}

.notify-form input {
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
}

.notify-form button {
  padding: 10px 20px;
  background-color: #ff6600;
  color: black;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.notify-form button:hover {
  background-color: #e55b00;
}
